@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-container {

  .rt-starscale-container {
    .rt-rating-label-mobile {
      display: none;
    }
    .rt-form-group:not(.rt-answer-na) {
      display: flex;
      width: 100%;
      max-width: 800px;

      .rt-answer-option,
      .rt-answer-option:hover {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100px;
        background-color: transparent;

        .rt-control-input~.rt-control-label:before {
          display: none;
        }
      }

      .rt-control-input~.rt-control-label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;

        svg {
          max-width: 100%;
        }

        svg path {
          fill: colors.$sv-color-primary20;
          stroke: colors.$sv-color-primary20;

        }

        &:hover {

          svg path {
            fill: colors.$success;
            stroke: colors.$success;
          }
        }
      }

      .rt-control-input:focus~.rt-control-label svg {
        @extend .focus-ring;
      }

      .rt-control-input~.rt-control-label:before {
        display: none;
      }

      &.rt-num-of-stars-2 {
        .rt-answer-option:nth-child(-n+1) {
          .rt-control-input~.rt-control-label svg path {
            fill: colors.$success;
            stroke: colors.$success;
          }
        }
      }

      $totalStars: 10;

      @for $stars from 1 through $totalStars {

        &.rt-num-of-stars-#{$stars} {
          .rt-answer-option:nth-child(-n+#{$stars}) {
            .rt-control-input~.rt-control-label svg path {
              fill: colors.$success;
              stroke: colors.$success;
            }
          }
        }
      }
    }
  }

  .rt-matrix-container {
    table .rt-mtx-starscale {
      
      .rt-rating-label-mobile {
        display: none;
      }
      .rt-mtx-col-heading:not(.rt-mtx-col-na) {
        visibility: hidden;
        font-size: 0.5px;
      }
    }

    table .rt-mtx-starscale.rt-mtx-answers .rt-mtx-answer-choice:not(.rt-mtx-col-na) .rt-mtx-cell .rt-control-label:before {
      transform: none;
      background-color: transparent;
      border: 0;
      box-shadow: none !important;
    }

    table .rt-mtx-starscale.rt-mtx-answers .rt-mtx-answer-choice:not(.rt-mtx-col-na) .rt-mtx-cell {

      .rt-control-input:focus~.rt-control-label svg {
        @extend .focus-ring;
      }

      .rt-control-label {
        padding: 0;

        svg {
          max-width: 100%;
        }

        svg path {
          fill: colors.$sv-color-primary20;
          stroke: colors.$sv-color-primary20;
        }
      }
    }

    table .rt-mtx-starscale.rt-mtx-answers {
      display: table-row;
      flex: none;
      box-shadow: inset 0px -1px 0px colors.$sv-color-secondary20;

      .rt-mtx-answer-choice:not(.rt-mtx-col-na) {
        display: table-cell;
        min-width: 44px;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }

        .rt-mtx-cell {
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }

          .rt-mtx-cell-label {
            @extend .rt-sr-only;
          }
        }
      }

      &.rt-element-error {
        td.rt-mtx-answer-choice {
          background-color: colors.$sv-color-warning60;

          &:hover {
            background-color: colors.$sv-color-warning80;
          }
        }
      }

      .rt-mtx-answer-choice.rt-mtx-col-na {
        .rt-mtx-cell {
          .rt-mtx-cell-label {
            word-break: keep-all;
          }
        }
      }
    }

    @container surveyContainer  (min-width: 500px) {
      table .rt-mtx-starscale.rt-mtx-answers {
        display: table-row;
        flex: none;
        box-shadow: inset 0px -1px 0px colors.$sv-color-secondary20;

        .rt-mtx-answer-choice {
          display: table-cell;
          min-width: 44px;
        }
      }
    }


    $totalMtxStars: 10;

    @for $MtxStars from 1 through $totalMtxStars {
      //$childNr: calc($MtxStars + 1);

      table .rt-mtx-starscale.rt-mtx-answers.rt-starscale-selected-#{$MtxStars} {
        .rt-mtx-answer-choice:not(.rt-mtx-col-na):nth-child(-n+#{$MtxStars+1}) {
          .rt-control-input~.rt-control-label svg path {
            fill: colors.$success;
            stroke: colors.$success;
          }
        }
      }
    }
  }
}