@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-container {
  .rt-rankorder {
    max-width: 85%;

    .rt-rankorder-item {
      max-width: 100%;
    }
  }

  .rt-rankorder-drop-container {
    &.rt-rankorder-empty {
      padding: 2rem;
      border: 1px dashed colors.$success;
      background-color: colors.$sv-color-success20;
      border-radius: 0;
    }

    background-color: colors.$success;
    padding: .01rem .25rem;
    border-radius: .25rem;

    .rt-rankorder-item {

      .rt-rankorder-rank {
        &:after {
          content: '.';
        }
      }
    }
  }

  .rt-rankorder-seperator {
    border-top: 1px dotted colors.$sv-color-secondary40;
    margin: 0.5rem;
  }

  .rt-rankorder-grab-container {
    padding: 0 .25rem;

    &.rt-rankorder-empty {
      padding: 2rem;
      background-color: colors.$sv-color-secondary10;
      border-radius: 0;
      margin-bottom: .5rem;
    }
  }

  .rt-rankorder-drop-area {
    padding: 1.75rem;
    border: 1px dashed colors.$success;
    background-color: colors.$sv-color-success20;
    border-radius: 0;
    margin-top: .25rem;
    margin-bottom: .25rem;
    border-radius: .25rem;
  }
}

.rt-rankorder-item {
  &:hover {
    cursor: grab;
  }

  &:focus {
    @extend .focus-ring;
    outline: none;
  }

  .rt-rankorder-grab-icon:before {
    @include mixins.sv-fontawesome("\f7a4");
    color: colors.$sv-color-secondary40;
    min-width: 1rem;
    max-width: 1rem;
    align-self: right;
    margin-left: .5rem;
  }

  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 1rem;
  border-radius: .25rem;
  background-color: colors.$sv-color-secondary10;
  margin-top: .25rem;
  margin-bottom: .25rem;
  max-width: 960px;

  @container surveyContainer  (max-width: 1140px) {
    & {
      max-width: calc(85% - 3.25rem);
    }
  }

  .rt-rankorder-rank {
    font-weight: bold;
    display: inline-block;
    min-width: 2rem;
    max-width: 2rem;
    color: colors.$primary;
    text-align: center;
    padding-right: .25rem;
  }

  .rt-rankorder-label {
    width: 100%;
  }
}