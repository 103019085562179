@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-defaults';

$max-rt-width: 1200px;
$font-size-question: 1.5rem;
$font-size-hint: 1rem;
$font-size-option: 1rem;

* {
  box-sizing: border-box;
}

.slt-page-container {
  width: 100%;
  max-width: $max-rt-width;
  margin-left: auto;
  margin-right: auto;
}

.rt-container {

  h1 {
    font-size: 2.25rem;
    font-weight: bold;

    @container surveyContainer  (max-width: 768px) {
      & {
        font-size: 2rem;
      }
    }

    @container surveyContainer  (max-width: 400px) {
      & {
        font-size: 1.75rem;
      }
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;

    @container surveyContainer  (max-width: 768px) {
      & {
        font-size: 1.75rem;
      }
    }

    @container surveyContainer  (max-width: 400px) {
      & {
        font-size: 1.5rem;
      }
    }
  }

  h3 {
    font-size: 1.75rem;
    font-weight: bold;

    @container surveyContainer  (max-width: 768px) {
      & {
        font-size: 1.5rem;
      }
    }

    @container surveyContainer  (max-width: 400px) {
      & {
        font-size: 1.25rem;
      }
    }
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;

    @container surveyContainer  (max-width: 768px) {
      & {
        font-size: 1.5rem;
      }
    }

    @container surveyContainer  (max-width: 400px) {
      & {
        font-size: 1.25rem;
      }
    }
  }

  h5 {
    font-size: 1.25rem;
    font-weight: bold;

    @container surveyContainer  (max-width: 768px) {
      & {
        font-size: 1rem;
      }
    }

    @container surveyContainer  (max-width: 400px) {
      & {
        font-size: 1rem;
      }
    }
  }

  h6 {
    font-size: 1rem;
    font-weight: bold;
  }


  img {
    max-width: 100%;
  }

  .rt-header,
  .rt-controls-container {
    width: 100%;
    max-width: $max-rt-width;
    margin: auto;
  }

  .rt-controls-container {
    margin-top: 2.5rem;
    padding-bottom: 2.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	align-items: center;

    @container surveyContainer  (max-width: 575px) {
      &>* {
        margin: 1rem 0;
      }
    }

    .rt-control-right {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      text-align: right;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      -ms-flex-order: 1;
      order: 1;

      @container surveyContainer  (min-width: 576px) {
        & {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          -ms-flex-order: 2;
          order: 2;
        }
      }
    }

    .rt-control-left {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      text-align: left;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      -ms-flex-order: 2;
      order: 2;

      @container surveyContainer  (min-width: 576px) {
        & {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          -ms-flex-order: 1;
          order: 1;
        }
      }
    }
  }

  .rt-answer-na {
    padding-top: 1rem;
    border-top: 1px dashed colors.$sv-color-secondary20;

    &.rt-na-secondary {
      margin-bottom: 0px;

      .rt-control {
        margin-bottom: 0px;
        display: inline-block;
        background-color: transparent;

        &:hover {

          background-color: transparent;
        }
      }
    }
  }

  .rt-rating-labels {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .rt-rating-label-left {
      text-align: left;
    }

    .rt-rating-label-middle {
      text-align: center;
    }

    .rt-rating-label-right {
      text-align: right;
    }
  }

  .rt-element {
    @extend .bg-light;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: .5rem;
    margin-bottom: .25rem;
    margin-top: .25rem;

    &.rt-element-inactive{
      opacity: 0.5;
    }
    &.rt-element-active{
      opacity: 1;
    }

    @container surveyContainer  (min-width: 768px) {
      & {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: .5rem;
      }
    }

    .rt-qelement {
      padding-bottom: .5rem;
      padding-top: .5rem;

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      @container surveyContainer  (min-width: 768px) {
        & {
          padding-bottom: 1rem;
          padding-top: 1rem;
        }
      }
    }

    .rt-error {
      display: none;
    }

    .rt-qtext {
      font-size: $font-size-question;

      @container surveyContainer  (max-width: 768px) {
        & {
          font-size: calc(#{$font-size-question} * 0.8);
        }
      }

      @container surveyContainer  (max-width: 400px) {
        & {
          font-size: calc(#{$font-size-question} * 0.75);
        }
      }
    }

    .rt-qhint {
      font-size: $font-size-hint;

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      @container surveyContainer  (max-width: 768px) {
        & {
          font-size: calc(#{$font-size-hint} * 0.9);
        }
      }

      @container surveyContainer  (max-width: 400px) {
        & {
          font-size: calc(#{$font-size-hint} * 0.8);
        }
      }
    }
  }


  .rt-matrix-container {
    tr {
      &.rt-mtx-answers.rt-element-error,
      &.rt-mtx-subquestion-fullwidth.rt-element-error {
        border: 0 !important;

        &:hover {
          td {
            background-color: colors.$sv-color-warning60;
          }
        }

        td {
          background-color: colors.$sv-color-warning60;
        }

        td.rt-mtx-answer-choice:hover {
          background-color: colors.$sv-color-warning80;
        }
      }
    }
  }

  .rt-semantic-container {
    tr {
      &.rt-semantic-answers.rt-element-error {
        border: 0 !important;

        &:hover {
          td {
            background-color: colors.$sv-color-warning60;
          }
        }

        td {
          background-color: colors.$sv-color-warning60;
        }

        td.rt-semantic-answer-choice:hover {
          background-color: colors.$sv-color-warning80;
        }
      }
    }
  }

  .rt-element-error {
    border: 2px solid colors.$error !important;

    &:not(div) {
      border-width: 2px !important;
    }

    .rt-error {

      @extend .rt-danger-block;
      display: block;
      margin: .25rem 0;
      //color: $error;
      //font-size: 0.85rem;
      //font-weight: bold;
    }
  }
}