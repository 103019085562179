@use 'mixins';
@use 'colors';
@use 'fonts';
.rt-container {
  .dx-list {
    background-color: #fff;
  }

  .dx-textbox.dx-texteditor.dx-editor-outlined {
    border-radius: 4px;
  }

  .dx-textbox .dx-button-content div[class*="icon"],
  .dx-numberbox .dx-button-content div[class*="icon"] {
    border-radius: 4px;
  }

  .rt-drop-down-container {
    .dx-textbox.dx-texteditor.dx-editor-outlined {

      .dx-texteditor-input {
        padding: 0;
        height: 0;
        min-height: 0;
        border: 0;
      }
    }

    .rt-dropdown-choice-container {

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .dx-placeholder {
        left: 10px;
      }
    }

    .dx-button-content:after {
      content: none;
    }

    .dx-dropdowneditor-field-template-wrapper {
      min-height: 2rem;
      padding-left: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      div {
        align-self: center;
      }
    }

    .dx-state-focused {

      @extend .focus-ring;
    }
  }



  .dx-list-item {
    white-space: normal;

    .dx-list-item-content {
      overflow: auto;
      text-overflow: clip;
      white-space: normal;
    }

  }

  &.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected,
  &.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-focused,
  &.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused:not(.dx-list-item-selected) {
    background-color: colors.$success;
    color: colors.$sv-color-neutral;
  }

  &.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    @extend .focus-ring;
  }
}

.dx-popup-content {
  padding: 2px;
}