@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-container.rt-rtl {
  direction: rtl;
  text-align: initial;

  .rt-semantic-rating-label .rt-rating-labels .rt-rating-label-left .fa-arrow-left:before {
    content: "\f061";
  }

  .rt-semantic-rating-label .rt-rating-labels .rt-rating-label-right .fa-arrow-right:before {
    content: "\f060";
  }

  .rt-mtx-rating-label .rt-rating-labels .rt-rating-label-left .fa-arrow-left:before {
    content: "\f061";
  }

  .rt-mtx-rating-label .rt-rating-labels .rt-rating-label-right .fa-arrow-right:before {
    content: "\f060";
  }

  .rt-information-block:before,
  .rt-warning-block:before,
  .rt-success-block:before,
  .rt-danger-block:before {
    margin-left: auto;
    margin-right: -3.25rem;
    left: .9rem;
  }

  .rt-control-input {
    &~.rt-control-label {
      &:before {
        left: auto;
        right: 0.4rem;

        @container surveyContainer  (min-width: 768px) {
          & {
            left: auto;
            right: .65rem;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            left: auto;
            right: .45rem;
          }
        }
      }
    }
  }


  .rt-answer-option {
    .rt-control-label {
      padding: 0.4rem 2rem 0.4rem 1rem;

      @container surveyContainer  (min-width: 400px) {
        & {
          padding: 0.45rem 2.1rem 0.45rem 1.1rem;
        }
      }

      @container surveyContainer  (min-width: 768px) {
        & {
          padding: 0.625rem 2.5rem 0.625rem;
        }
      }
    }



    &.rt-has-input {
      .rt-semi-open-container {
        margin-left: 1.5rem;
        margin-right: 2rem;


        @container surveyContainer  (min-width: 400px) {
          & {
            margin-right: 2.1rem;
          }
        }

        @container surveyContainer  (min-width: 768px) {
          & {
            margin-right: 2.5rem;
          }
        }
      }
    }
  }

  .rt-implicit-container .rt-implicit-actions {
    .dislike {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .rt-implicit-dislike-icon {
        order: 2;
      }

      .rt-implicit-dislike-label {
        order: 1;
      }
    }

    .like {
      order: 1;
    }
  }

  .rt-drop-down-container .dx-dropdowneditor-field-template-wrapper,
  .rt-matrix-container table .rt-mtx-choice-has-dropdown .dx-dropdowneditor-field-template-wrapper {
    margin-right: 1rem;
    padding-left: 0.25rem;
  }

  .rt-dxslider {
    direction: ltr;

    .rt-slider-label-container {
      div {
        direction: rtl;
      }
    }
  }

  .dx-placeholder {
    left: auto;
    right: 0.5rem;
    text-align: initial;
  }

  .rt-input-group .rt-input-group-prepend~.rt-input-text,
  .rt-input-group .rt-input-group-append>.rt-input-group-text {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rt-input-group .rt-input-group-prepend>.rt-input-group-text,
  .rt-input-group .rt-input-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }


  .rt-controls-container {
    .rt-control-left {
      text-align: right
    }

    .rt-control-right {
      text-align: left
    }
  }

  .rt-matrix-container {
    table {
      .rt-mtx-choice-has-open {
        .rt-control-label {
          &+.rt-mtx-open-container {
            &.rt-mtx-cell-has-error {
              margin-left: 1.5rem;
              position: relative;
            }

            margin-right: auto;
          }
        }

        &.rt-mtx-open-small {
          .rt-control-label {
            &+.rt-mtx-open-container {
              margin-right: 2rem;
              margin-left: auto;


              @container surveyContainer  (min-width:400px) {
                & {
                  margin-right: 2.1rem;
                  margin-left: auto;
                }
              }
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            .rt-control-label {
              &+.rt-mtx-open-container {
                margin-right: 0;
                margin-left: 0;
              }
            }

            &.rt-mtx-open-small {
              .rt-control-label {
                &+.rt-mtx-open-container {
                  margin-left: 0;
                  margin-right: 0;
                }
              }
            }

            label {
              &+.rt-mtx-open-container {
                margin-right: 0;
                margin-left: 0;
              }
            }

            &.rt-mtx-open-small {
              label {
                &+.rt-mtx-open-container {
                  margin-right: 0;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      .rt-mtx-cell {
        .rt-control-label {
          @container surveyContainer  (min-width:768px) {
            & {
              padding-left: 0;
              padding-right: 0 !important;
            }

            &:before {
              right: 50%;
              transform: translate(50%, -50%);
            }
          }
        }

        .rt-mtx-cell-label {
          display: inline-block;

        }
      }
    }
  }

  .rt-semantic-container {
    @container surveyContainer  (min-width: 768px) {
      .rt-semantic-left-label {
        text-align: left;
      }

      .rt-semantic-right-label {
        text-align: right;
      }
    }

    table {
      .rt-semantic-choice-has-open {
        .rt-control-label {
          &+.rt-semantic-open-container {
            &.rt-semantic-cell-has-error {
              margin-right: 0;
              margin-left: 1.5rem;
            }

            margin-right: 0;
          }
        }

        &.rt-semantic-open-small {
          .rt-control-label {
            &+.rt-semantic-open-container {
              margin-left: 0;
              margin-right: 2.5rem;
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            .rt-control-label {
              &+.rt-semantic-open-container {
                margin-right: 0;
                margin-left: 0;
              }
            }

            &.rt-semantic-open-small {
              .rt-control-label {
                &+.rt-semantic-open-container {
                  margin-right: 0;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      .rt-semantic-cell {
        .rt-control-label {
          @container surveyContainer  (min-width:768px) {
            &:before {
              right: 50%;
              transform: translate(50%, -50%);
            }
          }
        }
      }
    }
  }

  .rt-input-error-icon:after {
    right: auto;
    left: -20px;
  }

  .rt-input-error-icon .rt-input-error-message {
    left: auto;
    right: 0;
  }
}