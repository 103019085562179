@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-sc-mc-question';
@use 'rt-rtl';

.rt-container {
  .rt-semantic-container {
    .rt-qelement {
      padding-bottom: 1.75rem;
      overflow-x: auto;
      -webkit-overflow-scrolling: auto;
      max-width: 100%;
      /* Overwrite the default to keep the scrollbar always visible */

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 10px;
        -webkit-overflow-scrolling: auto
      }

      &::-webkit-scrollbar-track {
        height: 10px !important;
        background: colors.$sv-color-secondary40 !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px !important;
        height: 10px;
        background: colors.$sv-color-dark !important;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
      }
    }

    .rt-semantic-col-seperator {
      display: none;
      min-width: 0.25rem;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0;
    }

    .rt-semantic-answer-labels-fullwidth,
    .rt-semantic-colgroup-labels-fullwidth,
    .rt-semantic-subquestion-fullwidth {
      display: none;
    }


    .rt-semantic-rating-labels {
      display: none;
    }

    .rt-semantic-left-label {
      margin-top: 1rem;
    }


    @container surveyContainer  (min-width: 768px) {
      .rt-semantic-col-seperator {
        display: table-cell;
        background-color: transparent;
      }

      .rt-semantic-left-label {
        text-align: right;
      }

      .rt-semantic-right-label {
        text-align: left;
      }

      .rt-semantic-colgroup-heading-mobile {
        display: none;
      }

      .rt-semantic-answer-labels-fullwidth,
      .rt-semantic-colgroup-labels-fullwidth,
      .rt-semantic-subquestion-fullwidth {
        display: table-row;
      }

      .rt-semantic-rating-labels {
        display: table-row;
      }
    }

    .rt-semantic-answer-labels-with-subquestion,
    .rt-semantic-colgroup-labels-with-subquestion {
      display: none;
    }

    .rt-semantic-subquestion-col,
    .rt-semantic-subquestion-fullwidth {
      display: none;
    }

    .rt-semantic-subquestion-col {

      &.rt-semantic-left-label {
        display: block;
      }

      &.rt-semantic-right-label {
        display: block;
      }
    }

    @container surveyContainer  (min-width: 768px) {
      .rt-semantic-subquestion-fullwidth {
        display: table-row;
      }

      .rt-semantic-subquestion-col {

        &.rt-semantic-left-label {
          display: none;
        }

        &.rt-semantic-right-label {
          display: none;
        }
      }
    }

    @container surveyContainer  (min-width: 992px) {

      .rt-semantic-subquestion-fullwidth,
      .rt-semantic-colgroup-labels-fullwidth,
      .rt-semantic-answer-labels-fullwidth {
        display: none;
      }

      .rt-semantic-answer-labels-with-subquestion,
      .rt-semantic-colgroup-labels-with-subquestion {
        display: table-row;

        td {
          background-color: colors.$sv-color-secondary20;
        }
      }

      .rt-semantic-subquestion-col {
        min-width: 150px;
        display: table-cell;
      }

      .rt-semantic-subquestion-col {

        &.rt-semantic-left-label {
          display: table-cell;
        }

        &.rt-semantic-right-label {
          display: table-cell;
        }
      }
    }





    table {
      width: 100%;

      .rt-semantic-subquestion {
        font-size: 1rem;
      }

      .rt-semantic-subquestion-fullwidth {
        .rt-semantic-subquestion {
          padding-top: 0.75rem;
        }
      }

      .rt-semantic-subquestion,
      .rt-semantic-col-heading {
        font-weight: bold;
      }

      .rt-semantic-col-heading {
        text-align: center;
        padding-left: .25rem;
        padding-right: .25rem;
      }

      @container surveyContainer  (min-width: 768px) {
        .rt-semantic-subquestion {
          font-weight: normal;
          padding: 0.5rem;
        }

        .rt-semantic-col-heading {
          background-color: colors.$sv-color-secondary20;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      .rt-rating-label-mobile,
      .rt-semantic-rating-label {
        font-style: italic;
      }

      .rt-semantic-colgroup-heading-mobile {
        padding-top: .75rem;
        padding-bottom: 0.5rem;
      }

      .rt-semantic-body {
        display: flex;
        flex-wrap: wrap;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table;
            width: 100%;
          }
        }
      }

      .rt-semantic-answer-choice {
        display: block;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-cell;
            min-width: 3rem;
          }
        }
      }

      .rt-semantic-choice-has-dropdown {

        @container surveyContainer  (min-width:768px) {
          & {
            min-width: 15rem;

            .rt-semantic-cell {
              padding: 1rem 0.25rem;
            }
          }
        }
      }

      .rt-semantic-choice-has-open {

        .rt-control-label {
          &+.rt-semantic-open-container {
            &.rt-semantic-cell-has-error {
              position: relative;
              margin-right: 1.5rem;
            }

            width: calc(100% - 2.5rem);
            margin-left: auto;
          }
        }

        &.rt-semantic-open-small {
          .rt-control-label {
            &+.rt-semantic-open-container {
              margin-left: 2.5rem;
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            .rt-control-label {
              &+.rt-semantic-open-container {
                width: 10rem;
                margin-left: 0;

                &.rt-semantic-cell-has-error {
                  width: 8.5rem;
                }
              }
            }

            &.rt-semantic-open-small {
              .rt-control-label {
                &+.rt-semantic-open-container {
                  margin-left: 0;
                }
              }
            }

            .rt-semantic-cell {
              padding: 1rem 0.25rem;

              .rt-control-label {
                width: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }

              .rt-error {
                display: block;
                width: 100%;
              }
            }
          }
        }

        &.rt-semantic-open-small {
          .rt-semantic-open-container {
            width: 6rem;

            &.rt-semantic-cell-has-error {
              width: 4.5rem;
            }
          }
        }
      }

      .rt-semantic-cell {
        @extend .rt-answer-option;
        margin-bottom: 0.25rem !important;
        background-color: colors.$sv-color-secondary10;
        padding: .25rem;

        &:hover {
          background-color: colors.$sv-color-secondary10;
        }

        @container surveyContainer  (min-width:768px) {
          & {
            margin-bottom: 0;
            background-color: transparent !important;
          }

          &:hover {
            background-color: transparent !important;
          }
        }


        &:not(.rt-control) {
          label {
            padding: .25rem;
            margin-bottom: 0;
            background-color: transparent;

            &:empty {
              display: none;
            }

            @container surveyContainer  (min-width:768px) {
              & {
                display: none;
              }
            }

            &:hover {
              background-color: transparent;
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: flex;
            align-items: stretch;
            height: 100%;
            margin-bottom: 0;
            justify-content: center;
          }
        }

        .rt-control-label {
          @container surveyContainer  (min-width:768px) {
            & {
              padding-left: 0;
              padding-right: 0 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              padding-right: inherit;
              min-height: calc(1rem * 2);
            }

            &:hover {
              background-color: transparent;
            }

            &:before {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

        }

        .rt-semantic-cell-label {
          display: inline;

          @container surveyContainer  (min-width:768px) {
            & {
              border: 0;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
            }
          }
        }
      }

      .rt-semantic-answers {
        height: 100%;
        display: block;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding: 1px;

        @container surveyContainer  (min-width:500px) {
          & {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-row;

            td {
              background-color: colors.$light;

              &.rt-semantic-answer-choice:hover {
                background-color: colors.$sv-color-secondary20;
              }
            }

            &:hover {
              td {

                -webkit-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                -moz-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                background-color: colors.$sv-color-secondary10;
              }
            }

          }
        }

        @container surveyContainer  (min-width:992px) {
          & {
            height: auto;
          }
        }
      }

      .rt-rating-label-mobile {
        @container surveyContainer  (min-width:768px) {
          & {
            display: none;
          }
        }
      }
    }
  }
}