@use 'mixins';
@use 'colors';
@use 'fonts';
.rt-container {
  .dx-textbox.dx-texteditor.dx-editor-outlined.rt-lang-selector {
    border: 0;
    display: inline-block;
    min-width: 150px;
    max-width: 100%;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.125);
      cursor: pointer;
    }
  }

  .rt-lang-selector-container {
    display: flex;
    width: 100%;

    .dx-texteditor-buttons-container {
      display: none;
    }

    .dx-dropdowneditor-field-template-wrapper {
      min-height: 2rem;
      padding-left: .5rem;
      padding-right: .5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .dx-dropdowneditor-field-template-wrapper div {
      align-self: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 1rem);
    }
  }



  .rt-lang-selector-icon:before {
    @include mixins.sv-fontawesome("\f0ac");
    margin-right: .5rem;
    color: colors.$sv-color-secondary40;
  }
}