@use 'mixins';
@use 'colors';
@use 'fonts';

//primary
.rt-container {
  & {
    position: relative;
    container-type: inline-size;
    container-name: surveyContainer;
  }
  .bg-primary {
    background-color: colors.$primary !important;
  }

  .bg-primary90 {
    background-color: colors.$sv-color-primary90;
  }

  .bg-primary80 {
    background-color: colors.$sv-color-primary80;
  }

  .bg-primary60 {
    background-color: colors.$sv-color-primary60;
  }

  .bg-primary40 {
    background-color: colors.$sv-color-primary40;
  }

  .bg-primary20 {
    background-color: colors.$sv-color-primary20;
  }

  .bg-primary10 {
    background-color: colors.$sv-color-primary10;
  }

  // Secondary
  .bg-secondary {
    background-color: colors.$secondary !important;
  }

  .bg-secondary80 {
    background-color: colors.$sv-color-secondary80;
  }

  .bg-secondary60 {
    background-color: colors.$sv-color-secondary60;
  }

  .bg-secondary40 {
    background-color: colors.$sv-color-secondary40;
  }

  .bg-secondary20 {
    background-color: colors.$sv-color-secondary20;
  }

  .bg-secondary10 {
    background-color: colors.$sv-color-secondary10;
  }

  // Success
  .bg-success {
    background-color: colors.$success !important;
  }

  .bg-success80 {
    background-color: colors.$sv-color-success80;
  }

  .bg-success60 {
    background-color: colors.$sv-color-success60;
  }

  .bg-success40 {
    background-color: colors.$sv-color-success40;
  }

  .bg-success20 {
    background-color: colors.$sv-color-success20;
  }

  .bg-success10 {
    background-color: colors.$sv-color-success10;
  }

  // Info
  .bg-info {
    background-color: colors.$info !important;
  }

  .bg-info80 {
    background-color: colors.$sv-color-info80;
  }

  .bg-info60 {
    background-color: colors.$sv-color-info60;
  }

  .bg-info40 {
    background-color: colors.$sv-color-info40;
  }

  .bg-info20 {
    background-color: colors.$sv-color-info20;
  }

  .bg-info10 {
    background-color: colors.$sv-color-info10;
  }

  // Warning
  .bg-warning {
    background-color: colors.$warning !important;
  }

  .bg-warning80 {
    background-color: colors.$sv-color-warning80;
  }

  .bg-warning60 {
    background-color: colors.$sv-color-warning60;
  }

  .bg-warning40 {
    background-color: colors.$sv-color-warning40;
  }

  .bg-warning20 {
    background-color: colors.$sv-color-warning20;
  }

  .bg-warning10 {
    background-color: colors.$sv-color-warning10;
  }

  // Danger
  .bg-danger {
    background-color: colors.$danger !important;
  }

  .bg-danger80 {
    background-color: colors.$sv-color-danger80;
  }

  .bg-danger60 {
    background-color: colors.$sv-color-danger60;
  }

  .bg-danger40 {
    background-color: colors.$sv-color-danger40;
  }

  .bg-danger20 {
    background-color: colors.$sv-color-danger20;
  }

  .bg-danger10 {
    background-color: colors.$sv-color-danger10;
  }

  .bg-light {
    background-color: colors.$light;
  }

  .bg-dark {
    background-color: colors.$dark;
  }

  .bg-error {
    background-color: colors.$error;
  }
}

:root {
  --rt-sc-mc-highlight: #{colors.$success};
  --labelbar-inactive-background: #{colors.$sv-color-secondary10};
  --labelbar-active-font-color: #{colors.$sv-color-neutral};
  --labelbar-inactive-font-color: #{colors.$sv-color-dark};
  --labelbar-hover-background: #{colors.$sv-color-primary20};
  --labelbar-hover-font-color: #{colors.$sv-color-dark};
  --labelbar-border-radius: 0px;
  --labelbar-inactive-border-width: 0px;
  --labelbar-inactive-border-style: solid;
  --labelbar-inactive-border-color: transparent;
  --labelbar-active-border-width: 0px;
  --labelbar-active-border-style: solid;
  --labelbar-active-border-color: transparent;
  --labelbar-hover-border-width: 0px;
  --labelbar-hover-border-style: solid;
  --labelbar-hover-border-color: transparent;
}
body.keyboard-tab {
  .form-control {
    &:focus {
      border-color: colors.$success;
    }
  }
}

body:not(.keyboard-tab) {
  *:focus {
    outline: none;
  }
}


.rt-container {
  display: block;
  font-family: fonts.$font-family-sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: colors.$sv-color-dark;
  text-align: left;
  background-color: colors.$sv-color-neutral;

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  table {
    border-collapse: collapse;
  }

  .fr-view table.no-border td, .fr-view table.no-border th{
    border: 0 !important;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input:invalid {
    box-shadow: none;
  }

  .rt-form-group {
    margin-bottom: 1rem;
  }

  .fr-view {
    p {
      margin: 0;
    }
  }

  .rt-page-nav-container{
    position: sticky;
    top: calc(100vh - 50px);
    right: 0px;
    display: flex;
    margin-left: auto;
    width: 88px;
    justify-content: space-between;
    z-index:100;
    .rt-nav-btn{
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid colors.$sv-color-secondary40;
      background-color: colors.$sv-color-primary10;
      opacity: 0.85;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        opacity: 1;
        cursor:pointer;
      }
      &.rt-nav-btn-up:before{
        @include mixins.sv-fontawesome("\f077");
        font-size: 2rem;
      }
      &.rt-nav-btn-down:before{
        @include mixins.sv-fontawesome("\f078");
        font-size: 2rem;
      }
      &.rt-nav-btn-inactive{
        cursor: normal;
        opacity: 0.5;
        &:hover{
          opacity: 0.5;
        }
      }
    }

  }

  .rt-btn {
    min-width: 150px;
    display: inline-block;
    font-weight: 400;
    color: colors.$sv-color-dark;
    background-color: colors.$light;
    border: 1px solid colors.$sv-color-secondary40;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    letter-spacing: 1.1;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:hover {
      background-color: colors.$sv-color-secondary20;
      border-color: colors.$sv-color-secondary40;
    }

    @container surveyContainer (max-width: 575px) {
      & {
        min-width: 100%;
      }
    }

    &:focus {
      @extend .focus-ring;
      outline: none;
    }


    &.rt-btn-next {
      background-color: colors.$sv-color-primary90;
      border-color: colors.$sv-color-primary90;
      color: colors.$sv-color-neutral;
      font-weight: 700;

      &.rt-btn-save {
        @container surveyContainer  (max-width: 769px) {
          min-width: auto;
        }
      }

      &:hover {
        background-color: colors.$success;
        border-color: colors.$success;
        color: colors.$sv-color-neutral;
      }
    }

  }

  .rt-information-block {
    position: relative;
    padding: 0.75rem 1.5rem 0.75rem 4rem;
    margin-bottom: 1rem;

    &:before {
      position: absolute;
      font-size: 2rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-left: -3.25rem;
    }

    &.rt-information-block-sm {

      padding: 0.75rem .75rem 0.75rem 3rem;
      font-size: .9rem;

      &:before {
        font-size: 1.25rem;
        margin-left: -2.1rem;
      }
    }

    .rt-alert-close {
      position: absolute;
      top: 0;
      right: 0.5rem;
      outline: none;
      background-color: transparent;
      border: 0;
      display: block;
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      &:before {
        @include mixins.sv-fontawesome("\f00d");
        font-size: 1rem;
      }

      &:focus {
        @extend.focus-ring;
      }
    }
  }

  .rt-alert-container {
    position: fixed;
    top: 2px;
    width: 100%;
    left: 0;

    .rt-warning-block,
    .rt-info-block,
    .rt-success-block,
    .rt-danger-block {
      opacity: 0.9;
      margin-top: 0.25rem;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .rt-warning-block {
    @extend.rt-information-block;
    background-color: colors.$warning;
    color: colors.$sv-color-dark;

    &:before {
      @include mixins.sv-fontawesome("\f0ad");
    }
  }

  .rt-success-block {
    @extend.rt-information-block;
    background-color: colors.$info;
    color: colors.$sv-color-neutral;

    &:before {
      @include mixins.sv-fontawesome("\f118");
    }
  }


  .rt-danger-block {
    @extend.rt-information-block;
    background-color: colors.$error;
    color: colors.$sv-color-neutral;

    &:before {
      @include mixins.sv-fontawesome("\f119");
    }
  }

  .rt-input-error-icon {
    @extend.focus-ring;

    &:after {
      @include mixins.sv-fontawesome("\f06a");
      position: absolute;
      top: 4px;
      right: -20px;
      color: colors.$error;
    }

    .rt-input-error-message {
      padding: 5px;
      background-color: colors.$error;
      color: colors.$sv-color-neutral;
      display: none;
      z-index: 100;
      left: 0px;
      margin-top: 10px;
      min-width: 300px;
      max-width: 450px;
      position: absolute;
      top: 25px;
      text-decoration: none;
      border-radius: 5px;
      font-size: 0.85rem;
    }

    &:hover {
      .rt-input-error-message {
        display: block;
      }
    }

    &:focus {
      outline: none;

      &:after {
        box-shadow: 0 0 0 .2rem colors.$sv-color-success60 !important;
        border-radius: 50%;
      }

      .rt-input-error-message {
        display: block;
      }
    }
  }

}

.tooltip-element {
  position: relative;
  display: inline;
  border-bottom: 1px dotted colors.$dark;
}

.tooltip-element .tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: colors.$secondary;
  color: colors.$sv-color-neutral;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  max-width: 420px;

  &.bottom {

    bottom: auto;
    top: 125%;

    &:before {
      bottom: 100%;
      top: auto;
      border-color: transparent transparent colors.$secondary transparent;
    }
  }
}

.tooltip-element .tooltip-text:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: colors.$secondary transparent transparent transparent;
}

.dx-tooltip-wrapper .dx-overlay-content {
  max-width: calc(100% - 20px);
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  white-space: normal !important;
  max-width: 1024px;
}

.tooltip-element:hover {
  cursor: help;
}

.tooltip-element:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.rt-modal-spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: rgba(0, 0, 0, 0.1);

  &.rt-modal-closed {
    display: none;
  }

  .rt-modal {
    max-width: calc(100% - 1rem);
    max-height: 100%;
    width: 1000px;
    background-color: colors.$sv-color-neutral;
    padding: 1rem;
    box-shadow: 0px 0px 2px 1px #000;
    margin: 1rem;

    .rt-modal-head {
      position: relative;

      .rt-modal-close {
        position: absolute;
        top: -1.75rem;
        right: -1.75rem;
        color: colors.$sv-color-neutral;
        background-color: colors.$primary;
        border: 2px solid colors.$sv-color-neutral;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0px 0px 2px 1px #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .75rem;

        &:focus {
          outline: none;
          @extend .focus-ring;
        }
      }
    }

    .rt-modal-body {
      overflow: auto;
      height: 100%;
      max-height: 80vh;

      &:focus {
        outline: none;
        @extend .focus-ring;
      }
    }
  }
}

.no-scroll {
  overflow: hidden;
}

survalyzer-survey.sv-loaded {
  .sv-loading-survey-progress {
    display: none;
  }
}