@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-container {
  .rt-open-question-small {
    position: relative;
    max-width: 100%;
    width: 11.5rem;
  }

  .rt-open-question-middle {
    position: relative;
    max-width: 100%;
    width: 20rem;
  }

  .rt-open-question-small-has-text {
    position: relative;
    max-width: 100%;
    width: 11.5rem;
  }

  .rt-open-question-middle-has-text {
    position: relative;
    max-width: 100%;
    width: 25rem;
  }


  .rt-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;

    .rt-input-text {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .rt-input-group-text {
      border-radius: .2rem;
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      font-weight: 400;
      color: colors.$sv-color-dark;
      text-align: center;
      white-space: nowrap;
      background-color: colors.$sv-color-secondary10;
      border: 1px solid colors.$sv-color-secondary20;
    }

    .rt-input-group-prepend {
      margin-right: -1px;
      display: -ms-flexbox;
      display: flex;

      &>.rt-input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &~.rt-input-text {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .rt-input-group-append {
      margin-left: -1px;
      display: -ms-flexbox;
      display: flex;

      &>.rt-input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .rt-input-text {
    width: 100%;
    border-radius: .2rem;
    font-weight: 400;
    color: colors.$sv-color-dark;
    background-color: colors.$sv-color-neutral;
    background-clip: padding-box;
    border: 1px solid colors.$sv-color-secondary20;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:disabled,
    &:read-only:not(.dx-textbox):not(.dx-numberbox),
    &:read-only:not(.dx-textbox):not(.dx-numberbox):focus,
    &.dx-state-disabled,
    &.dx-state-readonly {
      background-color: colors.$sv-color-secondary10;
      opacity: 1;
      border: 1px solid colors.$sv-color-secondary20;
    }

    &:not(.dx-datebox):not(.dx-numberbox) {
      padding: .25rem .5rem;
    }

    &:focus {
      border-color: colors.$sv-color-dark;
      outline: none;
      @extend .focus-ring;
    }

    &:not(textarea) {
      height: calc(2rem + 2px);
    }

    &:is(textarea) {

      min-height: 8rem;

      max-height: 50vh;
    }



    input.dx-texteditor-input:not(.dx-searchbox) {
      min-height: auto;
      padding: 0.25rem 0.5rem;
      height: 100%;
    }

    &.dx-textbox.dx-autocomplete {
      input.dx-texteditor-input {
        padding: 0;
      }
	  
	  .dx-dropdowneditor-input-wrapper {
		overflow: inherit;
		
		.dx-texteditor-container {
		  overflow: inherit;
		}
	  }
    }

    &.dx-textbox,
    &.dx-numberbox {
      &:not(.dx-searchbox):not(.dx-colorbox):not(.dx-autocomplete) {

        input.dx-texteditor-input {
          padding: 0.25rem 0.5rem;
        }


        .dx-placeholder {
          left: 10px;
          font-size: 1rem;
        }
      }
    }

    .dx-placeholder:before {
      padding: 0;
	  width: 100%;
	  height: calc(100% + 8px);
	  display: flex;
	  align-items: center;    
	  margin-top: -4px;
	  overflow: hidden;
    }
  }

  .rt-textarea-no-rte {
    padding: 0.25rem 0.5rem;
    min-height: 8rem;
    width: 100%;
    overflow: auto;
    background-color: white;
    border: 1px solid colors.$sv-color-secondary20;
    color: colors.$sv-color-dark;
    font-size: 1rem;
    resize: none;

    &:focus {
      border-color: colors.$sv-color-dark;
    }
  }


  .dx-datebox.dx-auto-width .dx-texteditor-input,
  .dx-datebox:not(.dx-texteditor-empty).dx-auto-width .dx-texteditor-input {
    padding-right: 8px;
  }

  .dx-numberbox .dx-texteditor-input {
    text-align: right;
  }

  .dx-textbox.dx-texteditor.dx-editor-outlined,
  .dx-numberbox.dx-texteditor.dx-editor-outlined {
    border-radius: 4px;
  }

  .rt-input-group>.rt-input-text:not(:first-child).dx-textbox,
  .rt-input-group>.rt-input-text:not(:first-child).dx-numberbox {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-input-group>.rt-input-text:not(:last-child).dx-textbox,
  .rt-input-group>.rt-input-text:not(:last-child).dx-numberbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dx-texteditor.dx-state-hover.dx-editor-outlined {
    border-color: colors.$sv-color-secondary20;
  }

  .dx-texteditor.dx-state-focused.dx-editor-outlined {
    border-color: colors.$sv-color-dark;
  }


  .dx-state-focused {

    @extend .focus-ring;
  }
}

.dx-state-hover,
.dx-state-active {
  .rt-autocomplete-item {
    background-color: colors.$success;
    color: colors.$sv-color-neutral;
  }
}