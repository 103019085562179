@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-implicit {
  height: 350px;
  width: 350px;
  max-width: 100%;
  padding: 0.5rem;
  margin: auto;

  &:focus {
    outline: none;

    .rt-implicit-panel {
      @extend .focus-ring;
    }
  }

  &.rt-implicit-has-na {
    height: 375px;

    .rt-implicit-panel {
      padding: 35px 10px 10px 10px;
    }

    .rt-implicit-dislike,
    .rt-implicit-like {
      height: calc(100% - 35px);
      top: 35px;
    }
  }

  @container surveyContainer  (max-width: 335px) {
    & {
      height: 300px;
      width: 300px;

      &.rt-implicit-has-na {
        height: 325px;
      }
    }
  }
}

.rt-implicit-panel-container {
  margin: 0;
  position: relative;
  display: block;
  height: 100%;
}

.rt-implicit-done-message {
  display: flex;
  height: 475px;
  align-items: center;
  justify-content: center;
}

.rt-implicit-start-button {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;

  button {
    max-width: 200px;
    min-width: 200px;
    padding: .75rem;
    background-color: colors.$light;
    border: 1px solid colors.$sv-color-secondary40;
    border-radius: .25rem;
    box-shadow: none;

    &:hover {
      background-color: colors.$sv-color-secondary10;
    }

    &:focus {
      outline: none;
      @extend .focus-ring;
    }
  }
}
.rt-implicit-countdown {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.rt-implicit-countdown-label {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.rt-implicit-countdown-number {
  font-size: 4rem;
}
.rt-implicit-panel {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 2;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  overflow: hidden;
  text-align: center;
  padding: 10px 10px 10px 10px;
  background: colors.$light;
  font-size: 24px;
  border: 1px solid colors.$sv-color-secondary40;
  -webkit-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -moz-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -ms-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -o-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  .rt-implicit-stimuli-label {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(5n+1) {
    transform: rotate(-2deg);
  }

  &:nth-child(5n+2) {
    transform: rotate(2deg);
  }

  &:nth-child(5n+3) {
    transform: rotate(-1deg);
  }

  &:nth-child(5n+4) {
    transform: rotate(1deg);
  }

  .rt-implicit-na {
    display: flex;
    width: 100%;
    position: absolute;
    top: 3px;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 2;

    button {
      min-width: 150px;
      max-width: 100%;
      padding: .25rem;
      background-color: colors.$light;
      border: 1px solid colors.$sv-color-secondary40;
      border-radius: .25rem;
      box-shadow: none;
      font-size: 1rem;

      &:hover {
        background-color: colors.$sv-color-secondary10;
      }

      &:focus {
        outline: none;
        @extend .focus-ring;
      }
    }
  }

  .rt-implicit-dislike,
  .rt-implicit-like {
    &:before {
      margin: auto;
      font-size: 10rem;
    }

    display: flex;
    align-content: center;
    justify-content: center;
    opacity: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    -webkit-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
    -moz-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
    -ms-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
    -o-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }


  .rt-implicit-like {
    &:before {
      @include mixins.sv-fontawesome('\f164');
      color: colors.$success;
    }
  }

  .rt-implicit-dislike {
    &:before {
      @include mixins.sv-fontawesome('\f165');
      color: colors.$error;
    }
  }
}

.rt-implicit-actions {
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  align-items: center;

  button {
    max-width: 160px;
    min-width: 160px;
    padding: .75rem;
    background-color: colors.$light;
    border: 1px solid colors.$sv-color-secondary40;
    border-radius: .25rem;
    box-shadow: none;

    &:hover {
      background-color: colors.$sv-color-secondary10;
    }

    &:focus {
      outline: none;
      @extend .focus-ring;
    }

    @container surveyContainer  (max-width: 350px) {
      & {
        padding: .5rem;
        max-width: 145px;
        min-width: 145px;
      }
    }
  }

  .rt-implicit-like-icon {
    &:before {
      @include mixins.sv-fontawesome('\f164');
      color: colors.$success;
      padding-left: .5rem;
    }
  }

  .rt-implicit-dislike-icon {
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    -webkit-filter: none;
    filter: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    &:before {
      @include mixins.sv-fontawesome('\f165');
      color: colors.$error;
      padding-left: .5rem;
    }
  }
}