@use 'mixins';
@use 'colors';
@use 'fonts';

.rt-progress-container {
  width: 100%;
}

.rt-progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: colors.$sv-color-secondary20;
  border-radius: 0.15rem;
}

.rt-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: colors.$sv-color-neutral;
  text-align: center;
  white-space: nowrap;
  background-color: colors.$primary;
  transition: width 0.6s ease;
  min-width: 25px;

  &[aria-valuenow='0'] {
    min-width: 0px;

    .rt-progress-label {
      display: none;
    }
  }
}