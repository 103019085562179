@use 'colors';
@mixin sv-fontawesome($content) {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: $content;
}

.sv-rotate-icon {
    &:before {
        -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-filter: none;
        filter: none;
    }
}
body.keyboard-tab {
    .focus-ring {
      box-shadow: 0 0 0 .2rem colors.$sv-color-success60 !important;
    }
}


.rt-sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}