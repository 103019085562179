@use 'mixins';
@use 'colors';
@use 'fonts';
.rt-container{
    .rt-max-diff-container {
        .rt-qelement{
            display:flex;
            justify-content:center;
            position: relative;
        }
        .rt-max-diff-left-label {
             writing-mode: vertical-lr;
            transform: rotate(180deg);
            text-align: center;
        }
        .rt-max-diff-right-label {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            text-align: center;
        }
        .rt-max-diff-body{
            width: 100%;
            max-width: 500px;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            .rt-max-diff-choice{
                width: calc(100% - 44px);
                margin-right: 22px;
                margin-left: 22px;
                background-color: colors.$sv-color-secondary20;
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 0.5rem;
                min-height: 44px;
                transition: all 0.3s ease;
                
                @container surveyContainer  (min-width: 769px) {
                    & {
                        width: calc(100% - 88px);
                        margin-right: 44px;
                        margin-left: 44px;
                    }
                }
                input{
                    display:none;
                }
                .rt-max-diff-choice-left{
                    width: 44px;
                    max-width: 44px;
                    min-width: 44px;
                    margin-bottom:0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor:pointer;
                    &:focus {
                        @extend .focus-ring;
                        outline: none;
                      }
                      
                }
                .rt-max-diff-choice-left{
                    &:before{
                        color: colors.$secondary;
                        font-size: 1rem;
                        @include mixins.sv-fontawesome('\f100')
                    }
                }
                &.rt-max-diff-most-important{

                    .rt-max-diff-choice-left{
                        &:before{
                            color: colors.$success;
                            font-size: 1.7rem;
                            @include mixins.sv-fontawesome('\f164');
                            transform: scale(-1, 1);
                        }
                    }
                    .rt-max-diff-choice-right{
                        &:before{
                            opacity: 0;
                        }
                    }
                }
                .rt-max-diff-choice-label{
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    cursor:grab;
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
                    &.mousedown{
                        cursor:grabbing;
                    }
                    font-size: 0.7rem;
                    @container surveyContainer  (min-width: 769px) {
                        & {
                            font-size: 1rem;
                        }
                    }
                }
                .rt-max-diff-choice-right{
                    width: 44px;
                    max-width: 44px;
                    min-width: 44px;
                    margin-bottom:0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor:pointer;
                    &:focus {
                        @extend .focus-ring;
                        outline: none;
                      }
                }
                .rt-max-diff-choice-right{
                    &:before{
                        color: colors.$secondary;
                        font-size: 1rem;
                        @include mixins.sv-fontawesome('\f101');
                    }
                }
                &.rt-max-diff-least-important{
                    .rt-max-diff-choice-right{
                        &:before{
                            color: colors.$error;
                            font-size: 1.7rem;
                            @include mixins.sv-fontawesome('\f164');
                            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
                            -webkit-transform: rotate(180deg) scale(-1, 1);
                            transform: rotate(180deg) scale(-1, 1);
                            -webkit-filter: none;
                            filter: none;
                        }
                    }
                    .rt-max-diff-choice-left{
                        &:before{
                            content: '';
                        }
                    }
                }
                &.rt-max-diff-most-important {
                    margin-left: 0px;
                }
                &.rt-max-diff-least-important {
                    margin-left: 44px;
                    margin-right: 0px;
                
                    @container surveyContainer  (min-width: 769px) {
                        & {
                            margin-left: 88px;
                        }
                    }
                }
            }
        }

    }
}

