@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-sc-mc-question';
@use 'rt-rtl';
.rt-container {
  .rt-matrix-container {

    .rt-qelement {
      padding-bottom: 1.75rem;
      overflow-x: auto;
      -webkit-overflow-scrolling: auto;
      max-width: 100%;
      /* Overwrite the default to keep the scrollbar always visible */

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 10px;
        -webkit-overflow-scrolling: auto
      }

      &::-webkit-scrollbar-track {
        height: 10px !important;
        background: colors.$sv-color-secondary40 !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px !important;
        height: 10px;
        background: colors.$sv-color-dark !important;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
      }
    }

    .rt-mtx-col-seperator {
      display: none;
      min-width: 0.25rem;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0;
      width: .25rem;
      max-width: .25rem;
    }

    .rt-mtx-subquestion .rt-mtx-open-container {
      max-width: 80vw;
    }

    .rt-mtx-col-na {
      margin-top: 1rem;
    }

    .rt-mtx-answer-labels-fullwidth,
    .rt-mtx-colgroup-labels-fullwidth {
      display: none;
    }

    $total: 30;

    @container surveyContainer  (min-width: 992px) {
      @for $i from 1 through $total {

        .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
          width: calc(95% / #{$i});
          max-width: calc(95% / #{$i});
          min-width: calc(95% / #{$i});
        }
      }
    }

    @container surveyContainer  (min-width: 768px) {
      .rt-mtx-col-seperator {
        display: table-cell;
      }

      .rt-mtx-answer-labels-fullwidth,
      .rt-mtx-colgroup-labels-fullwidth {
        display: table-row;
      }

      .rt-mtx-colgroup-heading-mobile {
        display: none;
      }
    }

    .rt-mtx-rating-labels {
      display: none;
    }

    @container surveyContainer  (min-width: 768px) {

      .rt-mtx-answer-labels-fullwidth,
      .rt-mtx-colgroup-labels-fullwidth {
        display: table-row;
      }

      .rt-mtx-rating-labels {
        display: table-row;
      }
    }

    &.rt-mtx-subquestion--xl {
      .rt-mtx-subquestion-col {
        display: none;
      }

      .rt-mtx-answer-labels-with-subquestion,
      .rt-mtx-colgroup-labels-with-subquestion {
        display: none;
      }

      @container surveyContainer  (min-width: 992px) {
        @for $i from 1 through $total {

          .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
            width: calc(98% / #{$i});
            max-width: calc(98% / #{$i});
            min-width: calc(98% / #{$i});
          }
        }
      }
    }

    &.rt-mtx-subquestion--lg {

      .rt-mtx-answer-labels-with-subquestion,
      .rt-mtx-colgroup-labels-with-subquestion {
        display: none;
      }

      .rt-mtx-subquestion-col {
        display: none;
      }

      @container surveyContainer  (min-width: 992px) {
        @for $i from 1 through $total {

          .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
            width: calc(65% / #{$i});
            max-width: calc(65% / #{$i});
            min-width: calc(65% / #{$i});
          }
        }


        .rt-mtx-subquestion-fullwidth,
        .rt-mtx-colgroup-labels-fullwidth,
        .rt-mtx-answer-labels-fullwidth {
          display: none;
        }

        .rt-mtx-answer-labels-with-subquestion,
        .rt-mtx-colgroup-labels-with-subquestion {
          display: table-row;
        }

        .rt-mtx-subquestion-col {
          width: 1%;
          display: table-cell;

          &>div:not(.rt-mtx-open-container):not(.rt-input-text),
          &>span {
            display: block;
            min-width: 350px;
            max-width: 350px;
            width: 350px;
          }
        }
      }
    }

    &.rt-mtx-subquestion--md {

      .rt-mtx-answer-labels-with-subquestion,
      .rt-mtx-colgroup-labels-with-subquestion {
        display: none;
      }

      .rt-mtx-subquestion-col {
        display: none;
      }

      @container surveyContainer  (min-width: 992px) {

        @for $i from 1 through $total {

          .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
            width: calc(70% / #{$i});
            max-width: calc(70% / #{$i});
            min-width: calc(70% / #{$i});
          }
        }

        .rt-mtx-subquestion-fullwidth,
        .rt-mtx-colgroup-labels-fullwidth,
        .rt-mtx-answer-labels-fullwidth {
          display: none;
        }

        .rt-mtx-answer-labels-with-subquestion,
        .rt-mtx-colgroup-labels-with-subquestion {
          display: table-row;
        }

        .rt-mtx-subquestion-col {
          width: 1%;
          display: table-cell;

          &>div:not(.rt-mtx-open-container):not(.rt-input-text),
          &>span {
            display: block;
            min-width: 300px;
            max-width: 300px;
            width: 300px;
          }
        }
      }
    }

    &.rt-mtx-subquestion--sm {

      .rt-mtx-answer-labels-with-subquestion,
      .rt-mtx-colgroup-labels-with-subquestion {
        display: none;
      }

      .rt-mtx-subquestion-col {
        display: none;
      }

      @container surveyContainer  (min-width: 992px) {

        @for $i from 1 through $total {

          .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
            width: calc(80% / #{$i});
            max-width: calc(80% / #{$i});
            min-width: calc(80% / #{$i});
          }
        }

        .rt-mtx-subquestion-fullwidth,
        .rt-mtx-colgroup-labels-fullwidth,
        .rt-mtx-answer-labels-fullwidth {
          display: none;
        }

        .rt-mtx-answer-labels-with-subquestion,
        .rt-mtx-colgroup-labels-with-subquestion {
          display: table-row;
        }

        .rt-mtx-subquestion-col {
          width: 1%;
          display: table-cell;

          &>div:not(.rt-mtx-open-container):not(.rt-input-text),
          &>span {
            display: block;
            min-width: 200px;
            max-width: 200px;
            width: 200px;
          }
        }
      }
    }




    table {
      width: 100%;

      .rt-mtx-subquestion {
        font-size: 1rem;
      }

      .rt-mtx-subquestion-fullwidth {
        display: block;
        width: 100%;

        .rt-mtx-subquestion {
          display: block;
          padding-top: 0.75rem;

          &:empty {
            display: none;
          }
        }
      }

      .rt-mtx-subquestion,
      .rt-mtx-col-heading,
      .rt-mtx-colgroup-heading {
        font-weight: bold;
      }

      .rt-mtx-col-heading,
      .rt-mtx-colgroup-heading {
        text-align: center;
        padding-left: .25rem;
        padding-right: .25rem;
        vertical-align: bottom;

      }

      @container surveyContainer  (min-width: 768px) {
        .rt-mtx-subquestion-fullwidth {
          display: table-row;

          .rt-mtx-subquestion {
            padding: 0.5rem;
            display: table-cell;
          }
        }

        .rt-mtx-subquestion {
          font-weight: normal;
        }


        .rt-mtx-col-heading,
        .rt-mtx-colgroup-heading {
          background-color: colors.$sv-color-secondary20;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      .rt-mtx-colgroup-heading-mobile,
      .rt-mtx-rating-label {
        font-style: italic;
      }

      .rt-mtx-colgroup-heading-mobile {
        margin-top: .75rem;
        padding-top: .75rem;
        padding-bottom: 0.5rem;
        display: block;

        @container surveyContainer  (min-width:768px) {
          & {
            display: none;
          }
        }
      }

      .rt-mtx-body {
        display: flex;
        flex-wrap: wrap;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table;
            width: 100%;
          }
        }
      }

      .rt-mtx-answer-choice {
        display: block;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-cell;
            min-width: 2.75rem;
          }
        }
      }

      .rt-mtx-choice-has-dropdown {
        @extend.rt-drop-down-container;
        max-width: 15rem;

        .dx-dropdownbox {
          width: 100%;
        }

        @container surveyContainer  (min-width:768px) {
          & {
            min-width: 15rem;

            .rt-mtx-cell {
              padding: 1rem 0.25rem;
            }
          }
        }
      }

      .rt-mtx-choice-has-open {

        .rt-control-label {
          &+.rt-mtx-open-container {
            &.rt-mtx-cell-has-error {
              margin-right: 1.5rem;
              position: relative;
            }

            position: relative;
            width: calc(100% - 2rem);
            margin-left: auto;

            @container surveyContainer  (min-width:400px) {
              & {
                width: calc(100% - 2.1rem);
              }
            }
          }
        }

        &.rt-mtx-open-small {
          .rt-control-label {
            &+.rt-mtx-open-container {
              position: relative;
              margin-left: 2rem;


              @container surveyContainer  (min-width:400px) {
                & {
                  margin-left: 2.1rem;
                }
              }
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            .rt-control-label {
              &+.rt-mtx-open-container {
                position: relative;
                width: 10rem;
                margin-left: 0;

                &.rt-mtx-cell-has-error {
                  width: 8.5rem;
                }
              }
            }

            &.rt-mtx-open-small {
              .rt-control-label {
                &+.rt-mtx-open-container {
                  position: relative;
                  margin-left: 0;
                }
              }
            }

            label {
              &+.rt-mtx-open-container {
                position: relative;
                width: 10rem;
                margin-left: 0;

                &.rt-mtx-cell-has-error {
                  width: 8.5rem;
                }
              }
            }

            &.rt-mtx-open-small {
              label {
                &+.rt-mtx-open-container {
                  position: relative;
                  margin-left: 0;
                }
              }
            }

            .rt-mtx-cell {
              position: relative;
              padding: 1rem 0.25rem;

              .rt-control-label {
                width: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }

              .rt-error {
                display: block;
                width: 100%;
              }
            }
          }
        }

        &.rt-mtx-open-small {
          .rt-mtx-open-container {
            position: relative;
            width: 7rem;

            &.rt-mtx-cell-has-error {
              width: 5.5rem;
            }
          }

        }
        &.rt-mtx-open-large {
          .rt-mtx-open-container {
            position: relative;
            min-width: 10rem;
            width: 100%;

            &.rt-mtx-cell-has-error {
              min-width: 7rem;
              width: calc(100% - 2rem);
            }
          }

        }
      }

      .rt-mtx-cell {
        @extend .rt-answer-option;
        margin-bottom: 0.25rem !important;
        background-color: colors.$sv-color-secondary10;
        padding: .25rem;

        &:hover {
          background-color: colors.$sv-color-secondary10;
        }

        @container surveyContainer  (min-width:768px) {
          & {
            margin-bottom: 0;
            background-color: transparent !important;
          }

          &:hover {
            background-color: transparent !important;
          }
        }


        &:not(.rt-control) {
          label {
            padding: .25rem;
            margin-bottom: 0;
            background-color: transparent;

            &:empty {
              display: none;
            }

            @container surveyContainer  (min-width:768px) {
              & {
                display: none;
              }
            }

            &:hover {
              background-color: transparent;
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: flex;
            align-items: stretch;
            height: 100%;
            margin-bottom: 0;
            justify-content: center;
          }
        }

        .rt-control-label {
          @container surveyContainer  (min-width:768px) {
            & {
              padding-left: 0;
              padding-right: 0 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              padding-right: inherit;
              min-height: calc(1rem * 2);
            }

            &:hover {
              background-color: transparent;
            }

            &:before {
              left: 50%;
              transform: translate(-50%, -50%);
            }

          }

        }

        .rt-mtx-cell-label {
          display: inline;

          @container surveyContainer  (min-width:768px) {
            & {
              border: 0;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
            }
          }
        }
      }

      .rt-mtx-answers {
        height: 100%;
        display: block;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;

        padding: 1px;

        @container surveyContainer  (min-width:500px) {
          & {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-row;

            box-shadow: inset 0px -1px 0px 0px colors.$sv-color-secondary10;

            &:last-child {
              box-shadow: none;
            }

            td {
              background-color: transparent;

              &.rt-mtx-answer-choice {
                &:hover {
                  background-color: colors.$sv-color-secondary20;
                }
              }
            }

            &:hover {
              td {
                -webkit-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                -moz-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                background-color: colors.$sv-color-secondary10;
              }
            }

          }
        }

        @container surveyContainer  (min-width:992px) {
          & {
            height: auto;
          }
        }
      }

      .rt-rating-label-mobile {
        @container surveyContainer  (min-width:768px) {
          & {
            display: none;
          }
        }
      }
    }
    .rt-mtx-rowgroup-heading{
      vertical-align: bottom;
      font-weight: bold;
      border-bottom: 2px solid #DADAD9;
    }

    &.rt-mtx-subquestion--sm .rt-mtx-rowgroup-heading-fullwidth,
    &.rt-mtx-subquestion--md .rt-mtx-rowgroup-heading-fullwidth,
    &.rt-mtx-subquestion--lg .rt-mtx-rowgroup-heading-fullwidth{
      display: none;
    }
    .rt-mtx-rowgroup-heading-fullwidth {
      font-weight: bold;
      border-bottom: 2px solid #DADAD9;
      margin-bottom: 2rem;
    }
    @container surveyContainer  (max-width: 992px){
      &.rt-mtx-subquestion--sm .rt-mtx-rowgroup-heading-fullwidth,
      &.rt-mtx-subquestion--md .rt-mtx-rowgroup-heading-fullwidth,
      &.rt-mtx-subquestion--lg .rt-mtx-rowgroup-heading-fullwidth{
        display: block;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }

    &.rt-labelbar {
      
       .rt-mtx-subquestion-col {
         display: none;
       }
        .rt-mtx-answer-labels-with-subquestion,
        .rt-mtx-colgroup-labels-with-subquestion {
         display: none;
       }
        table .rt-mtx-body {
         height: 100%;
       }
        .rt-mtx-answers {
         height: 100%;
       }
       @container surveyContainer  (min-width: 992px) {
        @for $i from 1 through $total {

          .rt-mtx-answers .rt-mtx-nr-of-choice-#{$i} {
            width: calc(98% / #{$i});
            max-width: calc(98% / #{$i});
            min-width: calc(98% / #{$i});
          }
        }
       }
        table .rt-mtx-answers .rt-mtx-answer-choice{
         background-color: transparent;
         vertical-align: middle;
         height: 100%;
       }
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-label:before{
         content: none;
       }
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-mtx-cell-label{
          height: auto;
          clip: auto;
          text-align: center;
          overflow: auto;
          position: static;
          width: auto;
       }
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input + .rt-control-label,
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input ~ .rt-mtx-open-container{
          background-color: var(--labelbar-inactive-background);
          border-radius: var(--labelbar-border-radius);
          border-width:var(--labelbar-inactive-border-width);
          border-style:var(--labelbar-inactive-border-style);
          border-color:var(--labelbar-inactive-border-color);
          padding: 15px !important;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          height: 100%;
          width: auto !important;
       }
       table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input:hover + .rt-control-label,
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input:hover ~ .rt-mtx-open-container{
          background-color: var(--labelbar-hover-background);
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-hover-font-color);
          border-width:var(--labelbar-hover-border-width);
          border-style:var(--labelbar-hover-border-style);
          border-color:var(--labelbar-hover-border-color);
        }
       .rt-mtx-open-container:empty {
            display: none;
        }
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input:checked + .rt-control-label,
          table .rt-mtx-answers .rt-mtx-answer-choice .rt-control-input:checked ~ .rt-mtx-open-container{
          background-color: var(--rt-sc-mc-highlight) !important;
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-active-font-color);
          border-width:var(--labelbar-active-border-width);
          border-style:var(--labelbar-active-border-style);
          border-color:var(--labelbar-active-border-color);
       }
        table .rt-mtx-answers .rt-mtx-answer-choice .rt-mtx-cell{
         padding: 0;
         margin-bottom: 0 !important;
         display: flex;
         flex-direction: column;
       }
        table .rt-mtx-answer-labels-with-subquestion,
        table .rt-mtx-answer-labels-fullwidth{
         display: none;
       }
        table .rt-mtx-subquestion-fullwidth{
         display: table-row;
       }
    }
  }
}