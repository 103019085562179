@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-layout';

body.keyboard-tab {
  .rt-slider-container {
    .dx-slider {
      .dx-slider-handle {
        margin-right: -13px;

        .dx-overlay-content {
          visibility: visible;
        }

        &.not-visible {
          border: 0;
          background-color: transparent;
          background-image: none;
          margin-right: 10px;

          @container surveyContainer  (max-width: 768px) {
            & {
              margin-right: 3px;
            }
          }

          .dx-overlay-content {
            visibility: hidden;
          }

        }
      }
    }
  }

}

body:not(.keyboard-tab) {
  .rt-slider-container {
    .dx-slider {
      .dx-slider-handle {
        visibility: visible;


        &.not-visible {
          visibility: hidden;

        }
      }
    }
  }
}


.rt-container {
  .rt-slider-container {

    .rt-dxslider {
      padding-left: 0;
      padding-right: 0;
      margin-top: 1rem;
    }

    .rt-slider-label-container {
      @extend .rt-rating-labels;
      padding-top: .5rem;
      margin-left: .5rem;
      margin-right: .5rem;
    }

    .rt-slider-left-label {
      @extend .rt-rating-label-left;
      padding-left: 0;
    }

    .rt-slider-middle-label {

      @extend .rt-rating-label-middle;
      padding-left: .25rem;
      padding-right: .25rem;
    }

    .rt-slider-right-label {
      @extend .rt-rating-label-right;
      padding-right: 0;
    }

    .dx-slider {

      .dx-slider-handle,
      .dx-state-hover.dx-slider-handle:not(.dx-state-active),
      .dx-state-active.dx-slider-handle,
      .dx-state-focused.dx-slider-handle:not(.dx-state-active) {
        margin-top: -13px;
        margin-right: -13px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-image: linear-gradient(45deg, colors.$sv-color-neutral 0%, colors.$sv-color-secondary10 50%);
        border: 1px solid colors.$sv-color-secondary40;
      }

      .dx-trackbar-range.dx-slider-range.dx-slider-range-visible {
        height: 8px;
        border-radius: 4px;
        background-color: colors.$success;
        border-color: colors.$sv-color-secondary40;
      }

      .dx-slider-bar {
        margin: 14px;
        height: 8px;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        background-color: colors.$sv-color-dark;
        border-color: colors.$sv-color-dark;
      }
    }

    .dx-state-focused {

      @extend .focus-ring;
    }
  }
}