@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-sc-mc-question';
@use 'rt-rtl';

$columnCount: 11;

.rt-container {
  .rt-nps-container.rt-nps-cubic {

    .rt-qelement {
      padding-bottom: 1.75rem;
      overflow-x: auto;
      -webkit-overflow-scrolling: auto;
      max-width: 100%;
      /* Overwrite the default to keep the scrollbar always visible */

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 10px;
        -webkit-overflow-scrolling: auto
      }

      &::-webkit-scrollbar-track {
        height: 10px !important;
        background: colors.$sv-color-secondary40 !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px !important;
        height: 10px;
        background: colors.$sv-color-dark !important;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
      }
    }

    .rt-nps-col-seperator {
      display: none;
      min-width: 0.25rem;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0;
      width: .25rem;
      max-width: .25rem;
    }


    .rt-nps-col-na {
      margin-top: 1rem;
    }


    $total: 12;


    @container surveyContainer  (min-width: 992px) {
      @for $i from 10 through $total {

        .rt-nps-answers .rt-nps-nr-of-choice-#{$i} {
          width: (98% / #{$i});
          max-width: (98% / #{$i});
          min-width: (98% / #{$i});
        }
      }
    }

    @container surveyContainer  (min-width: 768px) {
      .rt-nps-col-seperator {
        display: table-cell;
      }
    }

    .rt-nps-rating-labels {
      display: none;
    }

    @container surveyContainer  (min-width: 768px) {

      .rt-nps-rating-labels {
        display: table-row;
      }
    }


    table {
      width: 100%;


      .rt-rating-label-mobile,
      .rt-nps-rating-label {
        font-style: italic;
      }

      .rt-nps-body {
        display: flex;
        flex-wrap: wrap;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table;
            width: 100%;
          }
        }
      }

      .rt-nps-answer-choice {
        display: block;
        background-color: transparent;
        vertical-align: middle;

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-cell;
            min-width: 2.75rem;
          }
        }

      }


      .rt-nps-cell {
        @extend .rt-answer-option;
        margin-bottom: 0 !important;
        background-color: var(--labelbar-inactive-background);
        padding: 0;

        &:hover {
          background-color: var(--labelbar-hover-background);
        }

        @container surveyContainer  (min-width:768px) {
          & {
            margin-bottom: 0;
            background-color: transparent !important;
          }

          &:hover {
            background-color: transparent !important;
          }
        }


        &:not(.rt-control) {
          label {
            padding: .25rem;
            margin-bottom: 0;
            background-color: transparent;

            &:empty {
              display: none;
            }

            @container surveyContainer  (min-width:768px) {
              & {
                display: none;
              }
            }

            &:hover {
              background-color: transparent;
            }
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: flex;
            align-items: stretch;
            height: 100%;
            margin-bottom: 0;
            justify-content: center;
          }
        }

        .rt-control-input:checked+.rt-control-label {
          background-color: var(--rt-sc-mc-highlight) !important;
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-active-font-color);
          border-width:var(--labelbar-active-border-width);
          border-style:var(--labelbar-active-border-style);
          border-color:var(--labelbar-active-border-color);
          font-weight: bold;
        }

        .rt-control-input:not(:checked)~.rt-control-label:hover {
          background-color: var(--labelbar-hover-background) !important;
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-hover-font-color);
          border-width:var(--labelbar-hover-border-width);
          border-style:var(--labelbar-hover-border-style);
          border-color:var(--labelbar-hover-border-color);
        }

        .rt-control-label {
          background-color: var(--labelbar-inactive-background);
          color: var(--labelbar-inactive-font-color);
          border-radius: var(--labelbar-border-radius);
          border-width:var(--labelbar-inactive-border-width);
          border-style:var(--labelbar-inactive-border-style);
          border-color:var(--labelbar-inactive-border-color);
          padding-left: 15px;
          padding-right: 15px;
          white-space: nowrap;



          &:before {
            content: none;
          }

          @container surveyContainer  (min-width:768px) {
            & {
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: calc(1rem * 2);
            }

            &:hover {
              background-color: transparent;
            }

            &:before {
              left: 50%;
              transform: translate(-50%, -50%);
            }

          }

        }

        .rt-nps-cell-label {
          display: inline-block;
          text-align: center;
        }
      }

      .rt-nps-answers {
        height: 100%;
        display: block;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;

        padding: 1px;

        @container surveyContainer  (min-width:500px) {
          & {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        @container surveyContainer  (min-width:768px) {
          & {
            display: table-row;

            box-shadow: inset 0px -1px 0px 0px colors.$sv-color-secondary10;

            &:last-child {
              box-shadow: none;
            }

            td {
              background-color: transparent;

              &.rt-nps-answer-choice {
                &:hover {
                  background-color: colors.$sv-color-secondary20;
                }
              }
            }

            &:hover {
              td {
                -webkit-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                -moz-box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                box-shadow: -1px 0px 0px 0px colors.$sv-color-neutral;
                background-color: colors.$sv-color-secondary10;
              }
            }

          }
        }

        @container surveyContainer  (min-width:992px) {
          & {
            height: auto;
          }
        }
      }

      .rt-rating-label-mobile {
        @container surveyContainer  (min-width:768px) {
          & {
            display: none;
          }
        }
      }
    }

    &.rt-animated {
      table {
        animation: cubicTable 1.2s;
        animation-fill-mode: forwards;

        .rt-nps-cell {

          .rt-control-label {
            animation: cubicElement 1.2s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}

.rt-container {
  .rt-nps-container.rt-nps-circular {

    .rt-qelement table,
    .rt-nps-rating-labels,
    .rt-nps-rating-label {
      display: block;
    }

    .rt-rating-labels .fas.fa-arrow-left,
    .rt-rating-labels .fas.fa-arrow-right {
      display: none;
    }

    .rt-nps-body {
      display: flex;
      flex-direction: column;
    }

    .rt-nps-rating-labels {
      order: 2;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -20px;
      margin-bottom: 80px;
      width: 100%;
    }

    .rt-nps-answers {
      order: 1;
      display: block;
      position: relative;
      width: 17em;
      height: 17em;
      border-radius: 50%;
      padding: 0;
      list-style: none;
      margin: 1rem auto 0;

      .rt-nps-answer-choice {
        position: absolute;
        text-align: center;
        line-height: 50px;
        top: 50%;
        left: 50%;
        transform: rotate(90deg) translate(7.5em) rotate(-90deg);

        .rt-control-label {
          height: 100%;
          width: 100%;
          line-height: 50px;
          border-radius: 50%;
          margin: -25px;
          height: 50px;
          width: 50px;
          min-height: auto;
          padding: 0;
          color: colors.$sv-color-dark;
          background-color: colors.$sv-color-secondary20;
        }

        &.rt-nps-col-na {

          transform: translate(-48.9%);
          left: 50%;
          top: 115%;
          width: auto;

          .rt-control-label {
            border-radius: 0;
            width: auto;
            height: auto;
            line-height: normal;
            padding: 15px;
          }
        }

        .rt-control-input:not(:checked)~.rt-control-label:hover {
          background-color: var(--labelbar-hover-background) !important;
          color: var(--labelbar-hover-font-color);
          border-width:var(--labelbar-hover-border-width);
          border-style:var(--labelbar-hover-border-style);
          border-color:var(--labelbar-hover-border-color);
        }

        .rt-control-input:checked~.rt-control-label {
          background-color: var(--rt-sc-mc-highlight) !important;
          color: var(--labelbar-active-font-color);
          border-width:var(--labelbar-active-border-width);
          border-style:var(--labelbar-active-border-style);
          border-color:var(--labelbar-active-border-color);
          font-weight: bold;
        }

        .rt-control-input~.rt-control-label:before {
          content: none !important;
        }
      }


      $degreeOfElement: 140;

      @for $i from 1 through $columnCount {

        $labelCount: $i - 1;
        $extraDegree: 240 - $degreeOfElement;

        .rt-nps-answer-choice:nth-child(#{$i}) {
          transform: rotate(#{$degreeOfElement}deg) translate(7.5em) rotate(-#{$degreeOfElement}deg);

          .rt-control-input:checked~.rt-control-label:before {
            content: '#{$labelCount}' !important;
            display: block;
            height: 175px;
            width: 175px;
            font-size: 80px;
            line-height: 175px;
            border: 0;
            position: absolute;
            transform: translate(-50%, -50%);
            background-color: transparent;
            box-shadow: none;
            color: colors.$sv-color-dark;

            @if $i==1 {
              top: -53px;
              left: 117px;
            }

            @else if $i==2 {
              top: -5px;
              left: 142px;
            }

            @else if $i==3 {
              top: 49px;
              left: 143px;
            }

            @else if $i==4 {
              top: 98px;
              left: 120px;
            }

            @else if $i==5 {
              top: 132px;
              left: 78px;
            }

            @else if $i==6 {
              top: 144px;
              left: 25px;
            }

            @else if $i==7 {
              top: 132px;
              left: -27px;
            }

            @else if $i==8 {
              top: 98px;
              left: -69px;
            }

            @else if $i==9 {
              top: 49px;
              left: -92px;
            }

            @else if $i==10 {
              top: -5px;
              left: -91px;
            }

            @else if $i==11 {
              top: -53px;
              left: -67px;
            }
          }
        }

        $degreeOfElement: $degreeOfElement+26;
      }

    }

    .rt-rating-label-mobile {
      display: none;
    }

    &.rt-nps-smilies {
      @for $i from 1 through $columnCount {

        $smileyNumber: $i - 1;

        .rt-nps-answer-choice:nth-child(#{$i}) {

          .rt-control-input:checked~.rt-control-label:before {
            content: ' ' !important;
            mask-image: url('images/nps-question/nps-#{$smileyNumber}.svg');
            background-color: var(--rt-sc-mc-highlight);
            height: 125px;
            width: 125px;
            border-radius: 0;
            mask-size: 100%;
            mask-repeat: no-repeat;
          }
        }

      }

      &.rt-animated {
        @for $i from 1 through $columnCount {

          $secondCount: $i + 1;
          $msCount: $secondCount*10;

          .rt-nps-answer-choice:nth-child(#{$i}) {

            .rt-control-input:checked~.rt-control-label:before {
              animation: smileyGrow .3s;
              animation-fill-mode: forwards;

            }
          }

        }
      }
    }

    &.rt-animated {

      @for $i from 1 through $columnCount {

        $secondCount: $i + 1;
        $msCount: $secondCount*10;

        .rt-nps-answer-choice:nth-child(#{$i}) {
          animation: circularElement#{$i} #{$msCount}0ms;
          animation-fill-mode: forwards;

          .rt-control-input:checked~.rt-control-label:before {
            animation: fontGrow .3s;
            animation-fill-mode: forwards;

          }
        }

      }
    }
  }
}

.rt-container.rt-rtl {
  .rt-nps-container.rt-nps-circular {
    .rt-nps-answers {
      $degreeOfElement: 400;

      @for $i from 1 through $columnCount {

        $labelCount: $i - 1;
        $extraDegree: 240 - $degreeOfElement;

        .rt-nps-answer-choice:nth-child(#{$i}) {
          transform: rotate(#{$degreeOfElement}deg) translate(7.5em) rotate(-#{$degreeOfElement}deg);

          .rt-control-input:checked~.rt-control-label:before {

            @if $i==11 {
              top: -53px;
              right: -240px;
              left: auto;
            }

            @else if $i==10 {
              top: -5px;
              right: -267px;
              left: auto;
            }

            @else if $i==9 {
              top: 49px;
              right: -267px;
              left: auto;
            }

            @else if $i==8 {
              top: 98px;
              right: -245px;
              left: auto;
            }

            @else if $i==7 {
              top: 132px;
              right: -202px;
              left: auto;
            }

            @else if $i==6 {
              top: 144px;
              right: -148px;
              left: auto;
            }

            @else if $i==5 {
              top: 132px;
              right: -94px;
              left: auto;
            }

            @else if $i==4 {
              top: 98px;
              right: -56px;
              left: auto;
            }

            @else if $i==3 {
              top: 49px;
              right: -34px;
              left: auto;
            }

            @else if $i==2 {
              top: -5px;
              right: -34px;
              left: auto;
            }

            @else if $i==1 {
              top: -53px;
              right: -59px;
              left: auto;
            }
          }
        }

        $degreeOfElement: $degreeOfElement - 26;
      }
    }

    &.rt-animated {

      @for $i from 1 through $columnCount {

        $secondCount: $i + 1;
        $msCount: $secondCount*10;

        .rt-nps-answer-choice:nth-child(#{$i}) {
          animation: circularElementRTL#{$i} #{$msCount}0ms;
          animation-fill-mode: forwards;
        }

      }
    }

    &.rt-nps-smilies {
      @for $i from 1 through $columnCount {

        $smileyNumber: $i - 1;

        .rt-nps-answer-choice:nth-child(#{$i}) {

          .rt-control-input:checked~.rt-control-label:before {
            @if $i==11 {
              right: -191px;
            }

            @else if $i==10 {
              right: -217px;
            }

            @else if $i==9 {
              right: -217px;
            }

            @else if $i==8 {
              right: -195px;
            }

            @else if $i==7 {
              right: -152px;
            }

            @else if $i==6 {
              right: -99px;
            }

            @else if $i==5 {
              right: -47px;
            }

            @else if $i==4 {
              right: -5px;
            }

            @else if $i==3 {
              right: 17px;
            }

            @else if $i==2 {
              right: 17px;
            }

            @else if $i==1 {
              right: -6px;
            }
          }
        }

      }
    }
  }
}

body.keyboard-tab .rt-container .rt-nps-container .rt-control-input:focus~.rt-control-label{
  box-shadow: 0 0 0 .2rem colors.$sv-color-success60 !important;
}

$columnCount: 11;
$degreeOfElementforAnimation: 140;

@for $i from 1 through $columnCount {

  $secondCount: $i + 1;


  @keyframes circularElement#{$i} {
    0% {
      transform: rotate(90deg) translate(7.5em) rotate(-90deg);
      animation-timing-function: ease-in-out
    }

    100% {
      transform: rotate(#{$degreeOfElementforAnimation}deg) translate(7.5em) rotate(-#{$degreeOfElementforAnimation}deg);
      animation-timing-function: ease-in-out
    }
  }

  $degreeOfElementforAnimation: $degreeOfElementforAnimation+26;
}

$columnCount: 11;
$degreeOfElementforAnimation: 400;

@for $i from 1 through $columnCount {

  $secondCount: $i + 1;


  @keyframes circularElementRTL#{$i} {
    0% {
      transform: rotate(90deg) translate(7.5em) rotate(-90deg);
      animation-timing-function: ease-in-out
    }

    100% {
      transform: rotate(#{$degreeOfElementforAnimation}deg) translate(7.5em) rotate(-#{$degreeOfElementforAnimation}deg);
      animation-timing-function: ease-in-out
    }
  }

  $degreeOfElementforAnimation: $degreeOfElementforAnimation - 26;
}

@keyframes cubicElement {

  0% {
    max-width: 0px;
    width: 0px;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    animation-timing-function: ease-out;
  }

  100% {
    max-width: 100%;
    width: 100%;
    overflow: visible;
    padding-left: 15px;
    padding-right: 15px;
    animation-timing-function: ease-out
  }
}

@keyframes cubicTable {

  0% {
    width: 0px;
    overflow: hidden;
    animation-timing-function: ease-out;
  }

  100% {
    width: 100%;
    overflow: visible;
    animation-timing-function: ease-out
  }
}

@keyframes fontGrow {

  0% {
    font-size: 0px;
    animation-timing-function: ease-out;
  }

  100% {
    font-size: 80px;
    animation-timing-function: ease-out
  }
}

@keyframes smileyGrow {

  0% {
    height: 0px;
    width: 0px;
    animation-timing-function: ease-out;
  }

  100% {
    height: 125px;
    width: 125px;
    animation-timing-function: ease-out
  }
}