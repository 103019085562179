@use 'mixins';
@use 'colors';
@use 'fonts';
@use 'rt-defaults';


$font-size-option: 1rem;

.rt-container {
  
  .rt-sc-mc-group {
    break-inside: avoid-column;
  }
  .rt-sc-mc-group-title {
    font-weight: bold;
  }
  .rt-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
  }

  .rt-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:focus {
      &~.rt-control-label {
        &:before {
          @extend.focus-ring;
        }
      }
    }

    &~.rt-control-label {
      cursor: pointer;

      &:before {
        position: absolute;
        top: 50%;
        left: 0.4rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: 1px solid colors.$sv-color-secondary20;
        font-size: 10px;
        text-align: center;
        line-height: 18px;
        transform: translate(0, -50%);

        @container surveyContainer  (min-width: 768px) {
          & {
            left: .65rem;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            left: .45rem;
          }
        }
      }

      &.rt-radio-button {
        &:before {
          border-radius: 50%;
        }



        @container surveyContainer (min-width: 768px) {
          &:hover {
            &:before {
              background-color: colors.$success;
              box-shadow: inset 0px 0px 0px 4px colors.$sv-color-neutral;
              border-width: 1px;
            }
          }
        }
      }


      @container surveyContainer (min-width: 768px) {
        &.rt-checkbox-button {
          &:hover {
            &:before {
              color: colors.$success;
              @include mixins.sv-fontawesome("\f00c");
            }
          }
        }
      }
    }

    &:checked {
      &~.rt-control-label {
        &.rt-radio-button {
          &:before {
            box-shadow: inset 0px 0px 0px 4px colors.$success;
            background-color: colors.$sv-color-neutral;
            border-color: colors.$success;
          }

          @container surveyContainer (min-width: 768px) {
            &:hover {
              &:before {
                box-shadow: inset 0px 0px 0px 4px colors.$success;
                background-color: colors.$sv-color-neutral;
                border-color: colors.$success;
              }
            }
          }
        }

        &.rt-checkbox-button {
          &:before {
            @include mixins.sv-fontawesome("\f00c");
            background-color: colors.$success;
            color: colors.$sv-color-neutral;
            border: 1px solid colors.$success;
          }


          @container surveyContainer (min-width: 768px) {
            &:hover {
              &:before {
                @include mixins.sv-fontawesome("\f00c");
                background-color: colors.$success;
                color: colors.$sv-color-neutral;
                border: 1px solid colors.$success;
              }
            }
          }
        }
      }

    }
  }

  .rt-control-label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    padding: 0.625rem 2.5rem;
    width: 100%;
    font-size: calc(#{$font-size-option} * 0.9);
    min-height: calc(#{$font-size-option} * 2.5 * 0.8);
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;



    @container surveyContainer  (min-width: 400px) {
      & {
        font-size: calc(#{$font-size-option} * 0.95);
        min-height: calc(#{$font-size-option} * 2.5 * 0.85);
      }
    }

    @container surveyContainer  (min-width: 768px) {
      & {
        font-size: $font-size-option;
        min-height: calc(1rem * 2.75);
      }
    }
  }

  .rt-control {
    &:focus {
      @extend .focus-ring;
      border: 1px solid colors.$dark;
    }
  }



  .rt-answer-option {
    padding: 0;
    margin-bottom: 0.5rem !important;
    @extend .bg-secondary10;

    &:hover {
      @extend .bg-secondary20;
    }


    @container surveyContainer  (min-width: 400px) {
      & {
        margin-bottom: 0.8rem !important;
      }
    }

    @container surveyContainer  (min-width: 768px) {
      & {
        margin-bottom: 1rem !important;
      }
    }



    .rt-control-label {
      padding: 0.4rem 1rem 0.4rem 2rem;

      @container surveyContainer  (min-width: 400px) {
        & {
          padding: 0.45rem 1.1rem 0.45rem 2.1rem;
        }
      }

      @container surveyContainer  (min-width: 768px) {
        & {
          padding: 0.625rem 2.5rem 0.625rem;
        }
      }
    }



    &.rt-has-input {
      padding-bottom: 0.625rem;


      .rt-semi-open-container {
        position: relative;
        margin-right: 1.5rem;
        margin-left: 2rem;
        margin-bottom: 0.625rem;

        .dx-datebox-date,
        .dx-numberbox {
          max-width: 200px;

          &~.rt-input-error-icon:after {
            right: auto;
            left: -20px;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            margin-left: 2.1rem;
          }
        }

        @container surveyContainer  (min-width: 768px) {
          & {
            margin-left: 2.5rem;
          }
        }
      }



      .rt-semi-open-input {
        margin: 0;
        width: 100%;
      }
    }
  }

  .rt-avoid-column-break {

    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
  }

  .rt-qelement {
    .rt-columns-4 {
      columns: 250px 4;

      .rt-answer-na {
        @extend .rt-avoid-column-break;
        padding-top: 0;
        border-top: 0;
      }

      .rt-control {
        display: table;
        margin-bottom: 0.5rem !important;
        width: 100%;
        @extend .rt-avoid-column-break;

        @container surveyContainer  (min-width: 768px) {
          & {
            margin-bottom: 1rem !important;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            margin-bottom: 0.8rem !important;
          }
        }
      }
    }

    .rt-columns-3 {
      columns: 300px 3;

      .rt-answer-na {
        @extend .rt-avoid-column-break;
        padding-top: 0;
        border-top: 0;
      }

      .rt-control {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        margin-bottom: 0.5rem !important;
        break-inside: avoid-column;
        display: table;
        width: 100%;

        @container surveyContainer  (min-width: 768px) {
          & {
            margin-bottom: 1rem !important;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            margin-bottom: 0.8rem !important;
          }
        }
      }
    }

    .rt-columns-2 {
      columns: 300px 2;

      .rt-answer-na {
        @extend .rt-avoid-column-break;
        padding-top: 0;
        border-top: 0;
      }

      .rt-control {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
        margin-bottom: 0.5rem !important;
        display: table;
        width: 100%;

        @container surveyContainer  (min-width: 768px) {
          & {
            margin-bottom: 1rem !important;
          }
        }

        @container surveyContainer  (min-width: 400px) {
          & {
            margin-bottom: 0.8rem !important;
          }
        }
      }
    }

    @container surveyContainer  (max-width: 768px) {
      .rt-columns-4 {
        columns: 1;
      }

      .rt-columns-3 {
        columns: 1;
      }

      .rt-columns-2 {
        columns: 1;
      }
    }
  }


  .rt-sc-container.rt-labelbar,
  .rt-mc-container.rt-labelbar {
    sha-basic-single-item,
    sha-basic-multiple-item,
    &:not(.rt-likert) sha-not-answered .rt-answer-na{
      >.rt-control.rt-answer-option{
        &.rt-has-input{
          padding-bottom: 0;
          .rt-radio-button,
          .rt-checkbox-button{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          .rt-semi-open-container{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            sha-list-autocomplete {
              width: 100%;
            }
          }
        }
        .rt-radio-button:before,
        .rt-checkbox-button:before{
          content: none !important;
        }
        .rt-control-input:checked + .rt-radio-button,
        .rt-control-input:checked + .rt-checkbox-button,
        .rt-control-input:checked ~ .rt-semi-open-container,
        .rt-control-input:checked ~ .rt-semi-open-container {
          background-color: var(--rt-sc-mc-highlight) !important;
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-active-font-color);
          border-width:var(--labelbar-active-border-width);
          border-style:var(--labelbar-active-border-style);
          border-color:var(--labelbar-active-border-color);
        }
        .rt-radio-button:hover,
        .rt-checkbox-button:hover,
        .rt-radio-button:hover ~ .rt-semi-open-container,
        .rt-checkbox-button:hover ~ .rt-semi-open-container {
          background-color: var(--labelbar-hover-background) !important;
          border-radius: var(--labelbar-border-radius);
          color: var(--labelbar-hover-font-color);
          border-width:var(--labelbar-hover-border-width);
          border-style:var(--labelbar-hover-border-style);
          border-color:var(--labelbar-hover-border-color);
        }
        .rt-control-input:not(:checked) ~ .rt-semi-open-container {
          display: none;
        }
        .rt-radio-button,
        .rt-checkbox-button{
          background-color: var(--labelbar-inactive-background);
          color: var(--labelbar-inactive-font-color);
          border-radius: var(--labelbar-border-radius);
          border-width:var(--labelbar-inactive-border-width);
          border-style:var(--labelbar-inactive-border-style);
          border-color:var(--labelbar-inactive-border-color);
          border-radius: 0px;
          margin-left: 0px;
          margin-right: 0px;
          text-align: center;
          min-width: 49px;
          padding-left: 15px !important;
          padding-right: 15px !important;
          font-size: 16px;
          display:flex;
          justify-content:center;
        }
        .rt-semi-open-container{
          background-color: var(--labelbar-inactive-background);
          margin-left: 0;
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          display:flex;
          justify-content:center;
        }
      }
    }
  }
  $total: 30;
  .rt-sc-rating-labels {
    display: none;
  }
  .rt-sc-container.rt-likert {
    sha-not-answered:empty {
      display:none;
    }
    
    .rt-rating-label-mobile {
      display: inline-block;
      font-style:italic;
    }
    .rt-sc-rating-labels {
      display: none;
    }
    &:not(.rt-sc-keep-horizontal){
      @container surveyContainer  (min-width: 768px) {
        @for $i from 1 through $total {
          &.rt-sc-nr-of-choices-#{$i} sha-basic-single-item {
            display:flex;
            width: calc(100% / #{$i});
            max-width: calc(100% / #{$i});
            min-width: calc(100% / #{$i});
          }
        }
        sha-not-answered {
          display:flex;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
        .rt-rating-label-mobile {
          display: none;
        }
        
        .rt-sc-rating-labels {
          display: block;
          padding: 4px 8px;
        }

        .rt-qelement{
          >.rt-form-group{
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            width: 100%;
            .rt-answer-na, 
            .rt-answer-na>.rt-control.rt-answer-option
            {
              width: 100%;
            }
            .rt-control.rt-answer-option{
              margin: 0 4px;
            }
            .rt-answer-na,
            .rt-control.rt-answer-option{
              width: 100%;
              display: flex;
              align-content: center;
              &.rt-has-input {
                padding-bottom: 0;
                flex-direction: column;
                justify-content: stretch;
              }
              .rt-radio-button,
              .rt-checkbox-button{
                word-break: auto-phrase;
                display: flex;
                align-items: center;
              }
              .rt-semi-open-container{
                display:flex;
                justify-content: center;
                sha-list-autocomplete {
                  width: 100%;
                }
              }
            }
            .rt-control-input:not(:checked) ~ .rt-semi-open-container {
              display: none;
            }
          }
        }
      }
    }
    &.rt-sc-keep-horizontal {
      @for $i from 1 through $total {
        &.rt-sc-nr-of-choices-#{$i} sha-basic-single-item {
          display:flex;
          width: calc(100% / #{$i});
          max-width: calc(100% / #{$i});
          min-width: calc(100% / #{$i});
        }
      }
      sha-not-answered {
        display:flex;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
      .rt-rating-label-normal {
        display: none;
      }
      @container surveyContainer  (min-width: 768px){
        .rt-rating-label-mobile {
          display: none;
        }
        .rt-rating-label-normal {
          display: inline-block;
        }
      }
      
      .rt-sc-rating-labels {
        display: block;
        padding: 4px 8px;
      }

      .rt-qelement{
        >.rt-form-group{
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          width: 100%;
          .rt-answer-na, 
          .rt-answer-na>.rt-control.rt-answer-option
          {
            width: 100%;
          }
          .rt-control.rt-answer-option{
            margin: 0 4px;
          }
          .rt-answer-na,
          .rt-control.rt-answer-option{
            width: 100%;
            display: flex;
            align-content: center;
            &.rt-has-input {
              padding-bottom: 0;
              flex-direction: column;
              justify-content: stretch;
            }
            .rt-radio-button,
            .rt-checkbox-button{
              word-break: auto-phrase;
              display: flex;
              align-items: center;
            }
            .rt-semi-open-container{
              display:flex;
              justify-content: center;
            }
          }
          .rt-control-input:not(:checked) ~ .rt-semi-open-container {
            display: none;
          }
        }
      }
    }
  }
}

body.keyboard-tab .rt-container {
  .rt-sc-container.rt-labelbar,
  .rt-mc-container.rt-labelbar {
    .rt-control-input:focus ~ .rt-control-label {
      box-shadow: 0 0 0 .2rem colors.$sv-color-success60 !important;
    }
  }
}
