$common-assets-path: "../../assets/default" !default;
;

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Regular.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Bold.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Bold.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Italic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Italic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-BoldItalic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-BoldItalic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Black.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Black.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Black.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-BlackItalic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-BlackItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BlackItalic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Thin.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Thin.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Thin.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-ThinItalic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-ThinItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-ThinItalic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
}


@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-Light.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-Light.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Light.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$common-assets-path}/fonts/Lato-LightItalic.eot');
    /* IE9 Compat Modes */
    src: url('#{$common-assets-path}/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('#{$common-assets-path}/fonts/Lato-LightItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-LightItalic.woff') format('woff'),
    /* Modern Browsers */
    url('#{$common-assets-path}/fonts/Lato-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
}

$font-family-sans-serif : "LatoWeb",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;