@use 'mixins';
@use 'colors';
@use 'fonts';
  .rt-spin-container {
    text-align: center;
    margin-top: 20px;
    max-width: 140px;
    max-height: 120px;
    margin-left: auto;
    margin-right: auto;

    .rt-spin {
      border: 3px solid colors.$sv-color-success60;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border-radius: 50%;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
    }

    .rt-spin-loader-2 {
      border: 3px solid colors.$success;
      width: 110px;
      height: 110px;
      position: relative;
      top: -105px;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
    }

    .rt-spin-loader-3 {
      border: 3px solid colors.$sv-color-primary60;
      width: 120px;
      height: 120px;
      position: relative;
      top: -220px;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
    }

    .rt-spin-loader-4 {
      border: 3px solid colors.$primary;
      width: 130px;
      height: 130px;
      position: relative;
      top: -345px;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotateZ(-360deg)
      }

      100% {
        transform: rotateZ(0deg)
      }
    }

    @keyframes rotate2 {
      0% {
        transform: rotateZ(360deg)
      }

      100% {
        transform: rotateZ(0deg)
      }
    }

    .rt-spin-text {
      font-size: 15px;
      top: -420px;
      color: colors.$primary;
      position: relative;
    }
  }